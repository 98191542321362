// src/components/WealthManagement/Widgets/RiskManagementWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../WealthManagementWidget.styles';

const RiskManagementWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Risk Management</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Portfolio Risk Level:</span>
          <span>Moderate</span>
        </DataRow>
        <DataRow>
          <span>Volatility (1Y):</span>
          <span>12%</span>
        </DataRow>
        <DataRow>
          <span>Suggested Adjustments:</span>
          <span>Increase Bond Allocation</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default RiskManagementWidget;
