// src/components/TaxPlanning/Widgets/CurrentTaxPositionWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../TaxPlanningWidget.styles';

const CurrentTaxPositionWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Current Tax Position</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Taxable Income:</span>
          <span>$1,200,000</span>
        </DataRow>
        <DataRow>
          <span>Estimated Tax Liability:</span>
          <span>$400,000</span>
        </DataRow>
        <DataRow>
          <span>Deductions:</span>
          <span>$100,000</span>
        </DataRow>
        <DataRow>
          <span>Tax Rate:</span>
          <span>33.33%</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default CurrentTaxPositionWidget;
