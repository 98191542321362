import React from 'react';
import { ReportingContainer, ReportingHeader, ReportingBody, ReportingFooter } from './CustomReporting.styles';

const CustomReporting = ({ portfolio }) => {
  
  const generateReport = () => {
    // This is where you would generate a report based on the portfolio data
    const report = portfolio.map(asset => ({
      name: asset.assetName,
      symbol: asset.symbol,
      value: asset.quantity * asset.currentPrice,
      gains: asset.quantity * (asset.currentPrice - asset.purchasePrice),
    }));

    console.log('Generated Report:', report);
    // You can further process or export this report
  };

  return (
    <ReportingContainer>
      <ReportingHeader>
        <h3>Custom Reporting</h3>
      </ReportingHeader>
      <ReportingBody>
        <button onClick={generateReport}>Generate Report</button>
        <p>Report data will be logged to the console for now.</p>
      </ReportingBody>
      <ReportingFooter>
        <a href="#">Download Report</a>
      </ReportingFooter>
    </ReportingContainer>
  );
};

export default CustomReporting;
