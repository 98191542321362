import React, { useState, useEffect } from 'react';
import { WidgetContainer, WidgetHeader, WidgetBody } from '../Widgets/Widget.styles';

const EconIndicatorsWidget = () => {
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    // Placeholder for fetching economic indicators based on portfolio holdings
    const fetchIndicators = async () => {
      const indicatorsData = [
        { name: "GDP Growth Rate", value: "2.5%" },
        { name: "Inflation Rate", value: "1.8%" },
        { name: "Unemployment Rate", value: "4.2%" },
        { name: "Federal Funds Rate", value: "0.25%" },
        
        // Add more indicators here based on portfolio holdings
      ];
      setIndicators(indicatorsData);
    };

    fetchIndicators();
  }, []);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <h3>Economic Indicators</h3>
      </WidgetHeader>
      <WidgetBody>
        {indicators.length > 0 ? (
          <ul>
            {indicators.map((indicator, index) => (
              <li key={index}>
                <strong>{indicator.name}:</strong> {indicator.value}
              </li>
            ))}
          </ul>
        ) : (
          <p>Loading economic indicators...</p>
        )}
      </WidgetBody>
    </WidgetContainer>
  );
};

export default EconIndicatorsWidget;
