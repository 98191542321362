// src/components/WealthManagement/Widgets/AssetAllocationWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, ChartContainer } from '../WealthManagementWidget.styles';
import { Pie } from 'react-chartjs-2';
import { getAssetClassAllocation } from '../../FinancialTracking/InvestmentPortfolio/components/api';

const AssetAllocationWidget = ({ portfolio }) => (
  <WidgetContainer>
    <WidgetHeader>Asset Allocation</WidgetHeader>
    <WidgetContent>
      <ChartContainer>
        <Pie data={getAssetClassAllocation(portfolio)} />
      </ChartContainer>
    </WidgetContent>
  </WidgetContainer>
);

export default AssetAllocationWidget;
