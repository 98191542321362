import React from 'react';
import { GridContainer } from '../MainSection/MainSection.styles'; // Adjusted path
import ReviewComplianceWidget from './Widgets/ReviewComplianceWidget'; // Adjusted path
import AssetsHoldingsWidget from './Widgets//AssetsHoldingsWidget'; // Adjusted path
import BeneficiaryManagementWidget from './Widgets//BeneficiaryManagementWidget'; // Adjusted path
import TrustEstatePlanningWidget from './Widgets//TrustEstatePlanningWidget'; // Adjusted path
import TaxImplicationsWidget from './Widgets//TaxImplicationsWidget'; // Adjusted path
import LegacyPlanningWidget from './Widgets//LegacyPlanningWidget'; // Adjusted path
import DistributionsWithdrawalsWidget from './Widgets//DistributionsWithdrawalsWidget'; // Adjusted path

const EstatesTrustsSection = () => {
  return (
    <GridContainer>
      <div className="grid-item">
        <ReviewComplianceWidget />
      </div>
      <div className="grid-item">
        <AssetsHoldingsWidget />
      </div>
      <div className="grid-item">
        <BeneficiaryManagementWidget />
      </div>
      <div className="grid-item">
        <TrustEstatePlanningWidget />
      </div>
      <div className="grid-item">
        <TaxImplicationsWidget />
      </div>
      <div className="grid-item">
        <LegacyPlanningWidget />
      </div>
      <div className="grid-item">
        <DistributionsWithdrawalsWidget />
      </div>
    </GridContainer>
  );
};

export default EstatesTrustsSection;
