import styled from 'styled-components';

export const WidgetContainer = styled.div`
  background: rgba(0, 0, 0, 0.85);
  color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 1.5rem;
`;

export const WidgetHeader = styled.h3`
  font-family: 'Lora', serif;
  margin-bottom: 1rem;
`;

export const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
`;
