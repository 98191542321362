import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from './EstatesTrustsWidget.styles';

const ReviewComplianceWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Review & Compliance</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Next Review:</span>
          <span>25th Dec 2024</span>
        </DataRow>
        <DataRow>
          <span>Compliance Status:</span>
          <span>All Up to Date</span>
        </DataRow>
        <DataRow>
          <span>Upcoming Deadlines:</span>
          <span>Estate Tax Filing - 31st Jan 2024</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default ReviewComplianceWidget;
