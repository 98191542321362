import styled from 'styled-components';

export const ItemContainer = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #444;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const ItemHeader = styled.div`
  font-size: 1.2rem;
  color: #e0e0e0;
  margin-right: 1rem;
`;

export const ItemContent = styled.div`
  font-size: 1rem;
  color: #b0b0b0;
`;
