import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from './EstatesTrustsWidget.styles';

const TaxImplicationsWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Tax Implications</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Estate Tax Due:</span>
          <span>$10M by 31st Jan 2024</span>
        </DataRow>
        <DataRow>
          <span>Inheritance Tax Paid:</span>
          <span>$5M</span>
        </DataRow>
        <DataRow>
          <span>Upcoming Tax Strategies:</span>
          <span>Deferred Gains - Q2 2024</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default TaxImplicationsWidget;
