// src/components/TaxPlanning/Widgets/DeductionsCreditsWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../TaxPlanningWidget.styles';

const DeductionsCreditsWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Deductions & Credits</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Charitable Contributions:</span>
          <span>$50,000</span>
        </DataRow>
        <DataRow>
          <span>Mortgage Interest:</span>
          <span>$20,000</span>
        </DataRow>
        <DataRow>
          <span>Retirement Contributions:</span>
          <span>$19,500</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default DeductionsCreditsWidget;
