// /src/components/ClientProfile/ClientProfile.js
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth } from '../../firebaseConfig';
import EditProfile from './EditProfile';
import { ProfileContainer, ProfileDetails, EditButton } from './ClientProfile.styles';

const ClientProfile = () => {
  const [clientData, setClientData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const db = getFirestore();

  useEffect(() => {
    const fetchProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'clients', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setClientData(docSnap.data());
        } else {
          console.error("No client profile found.");
        }
      }
    };

    fetchProfile();
  }, [db]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleProfileUpdate = async (updatedData) => {
    const user = auth.currentUser;
    if (user) {
      const docRef = doc(db, 'clients', user.uid);
      await updateDoc(docRef, updatedData);
      setClientData(updatedData);
      setIsEditing(false);
    }
  };

  if (!clientData) return <div>Loading...</div>;

  return (
    <ProfileContainer>
      {isEditing ? (
        <EditProfile
          clientData={clientData}
          onSave={handleProfileUpdate}
          onCancel={handleEditToggle}
        />
      ) : (
        <ProfileDetails>
          <h2>{clientData.name}</h2>
          <p>Email: {clientData.email}</p>
          <p>Phone: {clientData.phone}</p>
          <p>Address: {clientData.address}</p>
          {/* Add more fields as needed */}
          <EditButton onClick={handleEditToggle}>Edit Profile</EditButton>
        </ProfileDetails>
      )}
    </ProfileContainer>
  );
};

export default ClientProfile;
