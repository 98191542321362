// src/components/TaxPlanning/Widgets/YearEndTaxStrategiesWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../TaxPlanningWidget.styles';

const YearEndTaxStrategiesWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Year-End Tax Strategies</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Tax-Loss Harvesting:</span>
          <span>Consider selling underperforming assets</span>
        </DataRow>
        <DataRow>
          <span>Charitable Donations:</span>
          <span>Maximize deductions with donations</span>
        </DataRow>
        <DataRow>
          <span>Retirement Contributions:</span>
          <span>Contribute to maximize tax-advantaged growth</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default YearEndTaxStrategiesWidget;
