// src/components/TaxPlanning/Widgets/UpcomingTaxDeadlinesWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../TaxPlanningWidget.styles';

const UpcomingTaxDeadlinesWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Upcoming Tax Deadlines</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Q4 Estimated Tax Payment:</span>
          <span>January 15, 2025</span>
        </DataRow>
        <DataRow>
          <span>Annual Tax Filing:</span>
          <span>April 15, 2025</span>
        </DataRow>
        <DataRow>
          <span>Trust Distribution Deadline:</span>
          <span>December 31, 2024</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default UpcomingTaxDeadlinesWidget;
