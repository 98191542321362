// src/components/FinancialTracking/FinancialTrackingDashboard.styles.js
import styled from 'styled-components';

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  color: #ffffff;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const Section = styled.div`
  background: rgba(20, 20, 20, 0.9);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);

  h3 {
    margin-bottom: 1rem;
    font-family: 'Lora', serif;
    color: #ffffff;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    color: #cccccc;
  }
`;
