// src/components/WealthManagement/Widgets/InvestmentStrategyReviewWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../WealthManagementWidget.styles';

const InvestmentStrategyReviewWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Investment Strategy & Review</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Current Strategy:</span>
          <span>Growth-Oriented</span>
        </DataRow>
        <DataRow>
          <span>Next Review Date:</span>
          <span>1st Feb 2025</span>
        </DataRow>
        <DataRow>
          <span>Recommended Adjustments:</span>
          <span>Reduce Equity Exposure</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default InvestmentStrategyReviewWidget;
