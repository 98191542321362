// src/components/WealthManagement/Widgets/PerformanceTrackingWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, ChartContainer } from '../WealthManagementWidget.styles';
import { Line } from 'react-chartjs-2';
import { getPerformanceData } from '../../FinancialTracking/InvestmentPortfolio/components/api';

const PerformanceTrackingWidget = ({ portfolio }) => (
  <WidgetContainer>
    <WidgetHeader>Performance Tracking</WidgetHeader>
    <WidgetContent>
      <ChartContainer>
        <Line data={getPerformanceData(portfolio)} />
      </ChartContainer>
    </WidgetContent>
  </WidgetContainer>
);

export default PerformanceTrackingWidget;
