// src/components/WealthManagement/Widgets/CashFlowManagementWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../WealthManagementWidget.styles';

const CashFlowManagementWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Cash Flow Management</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Monthly Inflows:</span>
          <span>$1.5M</span>
        </DataRow>
        <DataRow>
          <span>Monthly Outflows:</span>
          <span>$1.2M</span>
        </DataRow>
        <DataRow>
          <span>Net Cash Flow:</span>
          <span>+ $300K</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default CashFlowManagementWidget;
