// src/components/TaxPlanning/Widgets/TaxEfficiencyWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../TaxPlanningWidget.styles';

const TaxEfficiencyWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Tax Efficiency</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Capital Gains Tax:</span>
          <span>20%</span>
        </DataRow>
        <DataRow>
          <span>Dividend Tax:</span>
          <span>15%</span>
        </DataRow>
        <DataRow>
          <span>Tax-Deferred Accounts:</span>
          <span>$500,000</span>
        </DataRow>
        <DataRow>
          <span>Taxable Accounts:</span>
          <span>$1,500,000</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default TaxEfficiencyWidget;
