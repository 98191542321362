import React from 'react';
import { ItemContainer, ItemHeader, ItemContent } from './MessageItem.styles';
import { Link } from 'react-router-dom';

const MessageItem = ({ message }) => (
  <ItemContainer>
    <ItemHeader>
      <Link to={`/messages/${message.id}`}>{message.subject}</Link>
    </ItemHeader>
    <ItemContent>
      <p>{message.preview}</p>
    </ItemContent>
  </ItemContainer>
);

export default MessageItem;
