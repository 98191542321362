// /src/components/ClientProfile/EditProfile.js
import React, { useState } from 'react';
import { EditProfileContainer, InputField, SaveButton, CancelButton } from './ClientProfile.styles';

const EditProfile = ({ clientData, onSave, onCancel }) => {
  const [updatedData, setUpdatedData] = useState(clientData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData, 
      [name]: value 
    }));
  };

  const handleSave = () => {
    onSave(updatedData);
  };

  return (
    <EditProfileContainer>
      <InputField
        type="text"
        name="name"
        value={updatedData.name}
        onChange={handleChange}
      />
      <InputField
        type="email"
        name="email"
        value={updatedData.email}
        onChange={handleChange}
      />
      {/* Add more fields as needed */}
      <SaveButton onClick={handleSave}>Save</SaveButton>
      <CancelButton onClick={onCancel}>Cancel</CancelButton>
    </EditProfileContainer>
  );
};

export default EditProfile;
