import React, { useEffect, useState } from 'react';
import { WidgetContainer, WidgetHeader, WidgetBody, WidgetFooter, MessageItemContainer, MessagePreview } from './MessageWidget.styles';
import { fetchMessages } from '../api';
import MessageItem from '../MessageItem/MessageItem';

const MessagesWidget = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const loadMessages = async () => {
      const data = await fetchMessages();
      setMessages(data);
    };

    loadMessages();
  }, []);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <h3>Messages</h3>
      </WidgetHeader>
      <WidgetBody>
        {messages.length === 0 ? (
          <p>No new messages.</p>
        ) : (
          messages.slice(0, 5).map((message) => (
            <MessageItem key={message.id} message={message} />
          ))
        )}
      </WidgetBody>
      <WidgetFooter>
        <a href="#">View all messages</a>
      </WidgetFooter>
    </WidgetContainer>
  );
};

export default MessagesWidget;
