import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from './EstatesTrustsWidget.styles';

const LegacyPlanningWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Legacy Planning</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Legacy Fund Status:</span>
          <span>$30M</span>
        </DataRow>
        <DataRow>
          <span>Charitable Contributions:</span>
          <span>$15M to Date</span>
        </DataRow>
        <DataRow>
          <span>Next Generational Planning:</span>
          <span>Q1 2024</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default LegacyPlanningWidget;
