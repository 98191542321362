import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import AdminHeader from './components/AdminHeader/AdminHeader';
import MainSection from './components/MainSection/MainSection';
import Footer from './components/Footer/Footer';
import SecureLogin from './components/SecureLogin/SecureLogin';
import { Container, GlobalStyle } from './styles/styles';
import { onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { auth } from './firebaseConfig';
import AdminDashboard from './admin/DataManagement/AdminDashboard';
import AnalyticsEntry from './admin/Analytics/AnalyticsEntry';
import ClientManagement from './admin/ClientManagement/ClientManagement';
import Reports from './admin/Reports/Reports';
import ClientProfile from './components/ClientProfile/ClientProfile';
import AddClient from './admin/ClientManagement/AddClient';
import ClientProfileAdminView from './admin/ClientManagement/ClientProfileAdminView';
import InviteClient from './admin/ClientOnboarding/InviteClient';
import OnboardingDashboard from './admin/ClientOnboarding/OnboardingDashboard';
import KYCUpload from './admin/ClientOnboarding/KYCUpload';
import OnboardingPage from './admin/ClientOnboarding/OnboardingPage';
import CreatePassword from './client/Onboarding/CreatePassword';
import KYCUploadClientView from './client/Onboarding/KYCUploadClientView';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          setLoading(true);
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const role = userDoc.data().role;
            setUserRole(role);
            setIsAuthenticated(true);
          } else {
            console.error('No user role found in Firestore.');
            setIsAuthenticated(false);
            setUserRole(null);
          }
        } catch (error) {
          console.error('Error fetching user role from Firestore:', error);
          setIsAuthenticated(false);
          setUserRole(null);
        } finally {
          setLoading(false);
        }
      } else {
        setIsAuthenticated(false);
        setUserRole(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [db]);

  const handleLogin = (user) => {
    if (user) {
      setIsAuthenticated(true);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUserRole(null);
    auth.signOut();
    window.location.href = '/';
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Router>
          {!isAuthenticated ? (
            <Routes>
              {/* Onboarding Routes: Accessible Without Authentication */}
              <Route path="/onboarding/create-password" element={<CreatePassword />} />
              <Route path="/onboarding/kyc-upload/:clientId" element={<KYCUploadClientView />} />

              {/* Redirect All Other Routes to Login */}
              <Route path="*" element={<SecureLogin onLogin={handleLogin} />} />
            </Routes>
          ) : (
            <>
              {/* Conditional Header Rendering Based on User Role */}
              {userRole === 'admin' ? (
                <AdminHeader onLogout={handleLogout} />
              ) : (
                <Header onLogout={handleLogout} />
              )}

              <Routes>
                {/* Universal Routes */}
                <Route path="/onboarding/create-password" element={<CreatePassword />} />
                <Route path="/onboarding/kyc-upload/:clientId" element={<KYCUploadClientView />} />

                {/* Conditionally Render Routes Based on User Role */}
                {userRole === 'client' && (
                  <>
                    <Route path="/overview" element={<MainSection activeSection="Overview" />} />
                    <Route path="/profile" element={<ClientProfile />} />
                    <Route path="/financials" element={<MainSection activeSection="Financials" />} />
                    <Route path="/documents" element={<MainSection activeSection="Documents" />} />
                    <Route path="/trusts" element={<MainSection activeSection="Trusts" />} />
                    <Route path="/tax" element={<MainSection activeSection="Tax" />} />
                    <Route path="/wealth" element={<MainSection activeSection="Wealth" />} />
                    <Route path="/mail" element={<MainSection activeSection="Mail" />} />
                    <Route path="/onboarding/:clientId" element={<OnboardingPage />} />
                    <Route path="/" element={<Navigate to="/overview" />} />
                  </>
                )}
                {userRole === 'admin' && (
                  <>
                    <Route path="/admin/dashboard" element={<AdminDashboard />} />
                    <Route path="/admin/analytics-entry" element={<AnalyticsEntry />} />
                    <Route path="/admin/client-management" element={<ClientManagement />} />
                    <Route path="/admin/client-management/add" element={<AddClient />} />
                    <Route path="/admin/client-management/:clientId" element={<ClientProfileAdminView />} />
                    <Route path="/admin/onboarding" element={<OnboardingDashboard />} />
                    <Route path="/admin/onboarding/invite" element={<InviteClient />} />
                    <Route path="/admin/onboarding/kyc/:clientId" element={<KYCUpload />} />
                    <Route path="/admin/reports" element={<Reports />} />
                    <Route path="/" element={<Navigate to="/admin/dashboard" />} />
                  </>
                )}
                {/* Fallback Route */}
                <Route path="/" element={<Navigate to="/" />} />
              </Routes>
              <Footer />
            </>
          )}
        </Router>
      </Container>
    </>
  );
}

export default App;
