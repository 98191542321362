import React, { useState, useEffect } from 'react';
import {
  PortfolioContainer,
  PortfolioHeader,
  PortfolioBody,
  PortfolioFooter,
} from './InvestmentPortfolio.styles';
import PortfolioOverview from './components/PortfolioOverview';
import TransactionForm from './components/TransactionForm';
import PortfolioTable from './components/PortfolioTable';
import CustomReporting from '../CustomReporting/CustomReporting';
import { fetchPriceData, fetchHistoricalData } from './components/api';
import { doc, setDoc, getDoc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../../firebaseConfig";

// Register Chart.js components
import { Chart, ArcElement, Tooltip as ChartTooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
Chart.register(ArcElement, ChartTooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

const InvestmentPortfolio = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [newTransaction, setNewTransaction] = useState({
    assetName: '',
    symbol: '',
    purchaseDate: '',
    quantity: '',
    purchasePrice: '',
    assetClass: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);

  const handleInputChange = (e) => {
    setNewTransaction({
      ...newTransaction,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditChange = (e, index) => {
    const updatedPortfolio = [...portfolio];
    updatedPortfolio[index][e.target.name] = e.target.value;
    setPortfolio(updatedPortfolio);
  };

  const validateTransaction = () => {
    const { assetName, symbol, purchaseDate, quantity, purchasePrice, assetClass } = newTransaction;
    if (!assetName || !symbol || !purchaseDate || !quantity || !purchasePrice || !assetClass) {
      return 'All fields are required.';
    }
    if (quantity <= 0 || purchasePrice <= 0) {
      return 'Quantity and Purchase Price must be greater than zero.';
    }
    return '';
  };

  const addTransaction = async () => {
    const validationError = validateTransaction();
    if (validationError) {
      setError(validationError);
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const currentPrice = await fetchPriceData(newTransaction.symbol);
      const historicalData = await fetchHistoricalData(newTransaction.symbol);

      setPortfolio((prevPortfolio) => [
        ...prevPortfolio,
        {
          ...newTransaction,
          quantity: parseFloat(newTransaction.quantity),
          purchasePrice: parseFloat(newTransaction.purchasePrice),
          currentPrice,
          historicalData,
        },
      ]);

      setNewTransaction({
        assetName: '',
        symbol: '',
        purchaseDate: '',
        quantity: '',
        purchasePrice: '',
        assetClass: '',
      });
    } catch (error) {
      console.error('Error adding transaction:', error);
      setError('An error occurred while adding the transaction.');
    } finally {
      setIsLoading(false);
    }
  };

  const calculatePortfolioValue = () => {
    return portfolio.reduce((total, asset) => {
      return total + asset.quantity * asset.currentPrice;
    }, 0).toFixed(2);
  };

  const calculateTotalGainsLosses = () => {
    return portfolio.reduce((total, asset) => {
      return total + asset.quantity * (asset.currentPrice - asset.purchasePrice);
    }, 0).toFixed(2);
  };

  const calculateROI = () => {
    const totalInvestment = portfolio.reduce((total, asset) => total + asset.quantity * asset.purchasePrice, 0);
    const totalValue = calculatePortfolioValue();
    return (((totalValue - totalInvestment) / totalInvestment) * 100).toFixed(2);
  };

  const calculateAnnualizedReturns = () => {
    const totalGains = calculateTotalGainsLosses();
    const totalInvestment = portfolio.reduce((total, asset) => total + asset.quantity * asset.purchasePrice, 0); // Calculate total investment here
    const years = 1; // Replace with the actual duration of the investments
    return ((Math.pow((totalGains / totalInvestment + 1), (1 / years)) - 1) * 100).toFixed(2);
  };



  useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const uid = user.uid;
          const portfolioDoc = await getDoc(doc(db, "portfolios", uid));
          if (portfolioDoc.exists()) {
            setPortfolio(portfolioDoc.data().portfolio);
          } else {
            console.log("No portfolio data found for this user.");
          }
        } else {
          console.error("No user is currently logged in.");
        }
      } catch (error) {
        console.error("Error fetching portfolio:", error);
      }
    };

    fetchPortfolio();
  }, []);

  useEffect(() => {
    if (portfolio.length > 0) {
      savePortfolio(portfolio);
    }
  }, [portfolio]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const unsubscribe = onSnapshot(doc(db, "portfolios", user.uid), (doc) => {
        if (doc.exists()) {
          setPortfolio(doc.data().portfolio);
        }
      });

      return () => unsubscribe();
    }
  }, []);

  const savePortfolio = async (portfolio) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        await setDoc(doc(db, "portfolios", uid), { portfolio });
        console.log("Portfolio saved successfully!");
      } else {
        console.error("No user is currently logged in.");
      }
    } catch (error) {
      console.error("Error saving portfolio:", error);
    }
  };

  return (
    <PortfolioContainer>
      <PortfolioHeader>
        <h3>Investment Portfolio</h3>
        <p>Total Value: ${calculatePortfolioValue()}</p>
      </PortfolioHeader>

      <PortfolioOverview
        totalGainsLosses={calculateTotalGainsLosses()}
        totalROI={calculateROI()}
        totalAnnualizedReturn={calculateAnnualizedReturns()}
        portfolio={portfolio}
      />

      <PortfolioBody>
        {error && <p style={{ color: '#ff6b6b' }}>{error}</p>}
        <TransactionForm
          newTransaction={newTransaction}
          handleInputChange={handleInputChange}
          addTransaction={addTransaction}
          isLoading={isLoading}
        />
        <PortfolioTable
          portfolio={portfolio}
          handleEditChange={handleEditChange}
          editingIndex={editingIndex}
          setEditingIndex={setEditingIndex}
          saveEdit={(index) => setEditingIndex(null)}
        />
      </PortfolioBody>
      <PortfolioFooter>
        <a href="#">View Detailed Portfolio</a>
      </PortfolioFooter>

      {/* Adding Custom Reporting */}
      <CustomReporting portfolio={portfolio} />
    </PortfolioContainer>
  );
};

export default InvestmentPortfolio;
