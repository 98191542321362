// Fetch mock price data for a given symbol
export const fetchPriceData = async (symbol) => {
  try {
    const mockPrices = {
      AAPL: 150.25,
      TSLA: 700.00,
      AMZN: 3300.00,
    };
    return mockPrices[symbol] || 0;
  } catch (error) {
    console.error(`Error fetching price data for ${symbol}:`, error);
    return 0;
  }
};

// Fetch mock historical data for a given symbol
export const fetchHistoricalData = async (symbol) => {
  try {
    const mockData = {
      AAPL: [148, 149, 150.25],
      TSLA: [690, 695, 700],
      AMZN: [3200, 3250, 3300],
    };
    return mockData[symbol] || [];
  } catch (error) {
    console.error(`Error fetching historical data for ${symbol}:`, error);
    return [];
  }
};

// Get asset class allocation from the portfolio
export const getAssetClassAllocation = (portfolio = []) => {
  if (!portfolio.length) {
    return {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: ['#D4AF37', '#8B0000', '#007bff', '#333333'],
        hoverBackgroundColor: ['#FFD700', '#FF4500', '#1E90FF', '#696969'],
      }],
      options: {},
    };
  }

  const allocation = portfolio.reduce((acc, asset) => {
    acc[asset.assetClass] = (acc[asset.assetClass] || 0) + asset.quantity * asset.currentPrice;
    return acc;
  }, {});

  const totalValue = Object.values(allocation).reduce((sum, value) => sum + parseFloat(value), 0);

  return {
    labels: Object.keys(allocation),
    datasets: [
      {
        data: Object.values(allocation).map((value) => value.toFixed(2)),
        backgroundColor: ['#D4AF37', '#8B0000', '#007bff', '#333333'],
        hoverBackgroundColor: ['#FFD700', '#FF4500', '#1E90FF', '#696969'],
        hoverOffset: 10,
      },
    ],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const label = tooltipItem.label;
              const value = tooltipItem.raw;
              const percentage = ((value / totalValue) * 100).toFixed(2);
              return `${label}: $${value} (${percentage}%)`;
            },
          },
        },
        legend: {
          position: 'bottom',
        },
      },
      onClick: (event, elements) => {
        if (elements.length > 0) {
          const chart = elements[0].chart;
          const index = elements[0].index;
          const assetClass = chart.data.labels[index];
          alert(`You clicked on ${assetClass}`);
        }
      },
    },
  };
};

// Get historical performance data
export const getHistoricalPerformance = (historicalData = []) => {
  return {
    labels: ['Day 1', 'Day 2', 'Day 3'],
    datasets: [
      {
        label: 'Price',
        data: historicalData,
        borderColor: '#D4AF37',
        backgroundColor: 'rgba(212, 175, 55, 0.2)',
      },
    ],
  };
};

// Get performance data for the portfolio
export const getPerformanceData = (portfolio = []) => {
  if (!portfolio.length) {
    return {
      labels: ['No Data'],
      datasets: [],
    };
  }

  const mockPerformanceData = portfolio.map((asset, index) => ({
    label: `Asset ${index + 1}`,
    data: asset.historicalData,
    borderColor: '#007bff',
    backgroundColor: 'rgba(0, 123, 255, 0.1)',
  }));

  return {
    labels: ['Day 1', 'Day 2', 'Day 3'],
    datasets: mockPerformanceData,
  };
};
