// src/components/FinancialTracking/BudgetingForecasting/BudgetingForecasting.styles.js
import styled from 'styled-components';

export const BudgetingContainer = styled.div`
  background: rgba(0, 0, 0, 0.85);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
  color: #ffffff;
  flex: 1;
  min-width: 300px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BudgetingHeader = styled.div`
  border-bottom: 1px solid #444;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  h3 {
    font-size: 1.5rem;
    font-family: 'Lora', serif;
    margin: 0;
    color: #e0e0e0;
  }
`;

export const BudgetingBody = styled.div`
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #b0b0b0;
  line-height: 1.6;

  p {
    margin: 0.5rem 0;
  }
`;

export const BudgetingFooter = styled.div`
  border-top: 1px solid #444;
  padding-top: 0.5rem;
  margin-top: 1rem;
  text-align: right;

  a {
    color: #ccc;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s;

    &:hover {
      color: #fff;
    }
  }
`;
