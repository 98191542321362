import styled from 'styled-components';

export const WidgetContainer = styled.div`
  background: rgba(0, 0, 0, 0.85);
  padding: 1.5rem;
  border-radius: 10px;  /* Slightly increased for a softer look */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.75);  /* Darkened shadow for more depth */
  color: #ffffff;
  flex: 1;
  min-width: 300px;
  margin: 1.5rem;  /* Increased margin for better spacing */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const WidgetHeader = styled.div`
  border-bottom: 1px solid #555;  /* Darkened border for a more cohesive look */
  padding-bottom: 0.75rem;  /* Increased padding for better spacing */
  margin-bottom: 1.5rem;  /* Increased margin for a clearer separation */

  h3 {
    font-size: 1.6rem;  /* Slightly larger font for more prominence */
    font-family: 'Lora', serif;
    margin: 0;
    color: #f0f0f0;  /* Lightened color for better contrast */
    letter-spacing: 0.5px;  /* Added letter-spacing for elegance */
  }
`;

export const WidgetBody = styled.div`
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #c0c0c0;  /* Slightly lightened color for better readability */
  line-height: 1.6;

  p {
    margin: 0.75rem 0;  /* Increased margin for improved spacing */
  }
`;

export const WidgetFooter = styled.div`
  border-top: 1px solid #555;  /* Darkened border to match header */
  padding-top: 0.75rem;  /* Increased padding for consistency */
  margin-top: 1.5rem;  /* Increased margin for better separation */
  text-align: right;

  a {
    color: #ddd;  /* Slightly lightened color for readability */
    text-decoration: none;
    font-size: 1rem;  /* Slightly larger font for emphasis */
    transition: color 0.3s;

    &:hover {
      color: #fff;
    }
  }
`;
