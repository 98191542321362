// src/components/TaxPlanning/TaxPlanningSection.js

import React from 'react';
import { GridContainer } from '../MainSection/MainSection.styles';
import CurrentTaxPositionWidget from './Widgets/CurrentTaxPositionWidget';
import TaxEfficiencyWidget from './Widgets/TaxEfficiencyWidget';
import UpcomingTaxDeadlinesWidget from './Widgets/UpcomingTaxDeadlinesWidget';
import DeductionsCreditsWidget from './Widgets/DeductionsCreditsWidget';
import YearEndTaxStrategiesWidget from './Widgets/YearEndTaxStrategiesWidget';
import ComplianceAuditRiskWidget from './Widgets/ComplianceAuditRiskWidget';

const TaxPlanningSection = () => {
  return (
    <GridContainer>
      <div className="grid-item">
        <CurrentTaxPositionWidget />
      </div>
      <div className="grid-item">
        <TaxEfficiencyWidget />
      </div>
      <div className="grid-item">
        <UpcomingTaxDeadlinesWidget />
      </div>
      <div className="grid-item">
        <DeductionsCreditsWidget />
      </div>
      <div className="grid-item">
        <YearEndTaxStrategiesWidget />
      </div>
      <div className="grid-item">
        <ComplianceAuditRiskWidget />
      </div>
    </GridContainer>
  );
};

export default TaxPlanningSection;
