// src/components/FinancialTracking/FinancialTrackingDashboard.js
import React from 'react';
import InvestmentPortfolio from './InvestmentPortfolio/InvestmentPortfolio';
import CustomReporting from './CustomReporting/CustomReporting';
import BudgetingForecasting from './BudgetingForecasting/BudgetingForecasting';
import { DashboardContainer, Section } from './FinancialTrackingDashboard.styles';




const FinancialTrackingDashboard = () => (
  <DashboardContainer>
    <Section>
      <InvestmentPortfolio />
    </Section>
    <Section>
      <CustomReporting />
    </Section>
    <Section>
      <BudgetingForecasting />
    </Section>
  </DashboardContainer>
);

export default FinancialTrackingDashboard;
