import styled from 'styled-components';

export const WidgetContainer = styled.div`
  background: rgba(0, 0, 0, 0.85);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
  color: #ffffff;
  flex: 1;
  min-width: 300px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const WidgetHeader = styled.div`
  border-bottom: 1px solid #444;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  h3 {
    font-size: 1.5rem;
    font-family: 'Lora', serif;
    margin: 0;
    color: #e0e0e0;
  }
`;

export const WidgetBody = styled.div`
  flex: 1;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #b0b0b0;
  line-height: 1.6;

  p {
    margin: 0.5rem 0;
  }
`;

export const WidgetFooter = styled.div`
  border-top: 1px solid #444;
  padding-top: 0.5rem;
  margin-top: 1rem;
  text-align: right;

  a {
    color: #ccc;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s;

    &:hover {
      color: #fff;
    }
  }
`;

export const MessageItemContainer = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #444;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const MessagePreview = styled.div`
  margin-left: 0.75rem;
  flex: 1;

  h4 {
    margin: 0;
    font-size: 1rem;
    color: #e0e0e0;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    color: #b0b0b0;
  }
`;
