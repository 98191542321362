export const fetchMessages = async () => {
  // Mock API call with data
  return [
    {
      id: 1,
      subject: 'Meeting Reminder',
      preview: 'Don’t forget our meeting at 10 AM...',
      body: 'We have a meeting scheduled for 10 AM tomorrow. Please be prepared.',
      sender: 'John Doe',
      date: '2024-08-13',
    },
    {
      id: 2,
      subject: 'Project Update',
      preview: 'The project is on track...',
      body: 'The project is progressing well and is on track for the deadline.',
      sender: 'Jane Smith',
      date: '2024-08-12',
    },
  ];
};

export const sendMessage = async (message) => {
  // Mock API call
  console.log('Message sent:', message);
};
