import React, { useState, useEffect } from 'react';
import { WidgetContainer, WidgetHeader, WidgetBody } from './Widget.styles';

const NewsWidget = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    // Placeholder for fetching news data
    const fetchNews = async () => {
      const newsData = [
        { title: "Global Markets Rally", link: "#" },
        { title: "Economic Forecast 2024", link: "#" },
        { title: "New Tax Regulations Announced", link: "#" },
        // Add more headlines here
      ];
      setNews(newsData);
    };

    fetchNews();
  }, []);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <h3>Latest News</h3>
      </WidgetHeader>
      <WidgetBody>
        {news.length > 0 ? (
          <ul>
            {news.map((item, index) => (
              <li key={index}>
                <a href={item.link}>{item.title}</a>
              </li>
            ))}
          </ul>
        ) : (
          <p>Loading news...</p>
        )}
      </WidgetBody>
    </WidgetContainer>
  );
};

export default NewsWidget;
