import styled from 'styled-components';

export const PortfolioContainer = styled.div`
  background: rgba(0, 0, 0, 0.85);
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  color: #ffffff;
`;

export const PortfolioHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  h3 {
    margin: 0;
    font-size: 1.8rem;
    font-family: 'Lora', serif;
    color: #ffffff;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  p {
    margin: 0;
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif;
    color: #cccccc;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

export const PortfolioBody = styled.div`
  display: flex;
  flex-direction: column;

  input, select {
    margin-right: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    border: 1px solid #555;
    background: #333;
    color: #ffffff;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 1rem;

    &:focus {
      outline: none;
      background: #444;
    }

    &::placeholder {
      color: #cccccc;
    }

    @media (max-width: 768px) {
      padding: 0.5rem 0.75rem;
      font-size: 0.9rem;
    }
  }

  button {
    padding: 0.75rem 1.5rem;
    background: #8b0000; /* Dark Red */
    border: none;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 1rem;
    transition: background 0.3s ease;

    &:hover {
      background: #a40000; /* Slightly brighter red */
    }

    &:active {
      transform: scale(0.95);
    }

    @media (max-width: 768px) {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }

  table {
    width: 100%;
    margin-top: 1.5rem;
    border-collapse: collapse;

    th, td {
      padding: 0.75rem;
      text-align: left;
      border-bottom: 1px solid #444;
      font-family: 'Open Sans', sans-serif;
    }

    th {
      color: #e6e6e6;
      font-size: 1rem;

      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }

    td {
      color: #a6a6a6;
      font-size: 0.95rem;

      @media (max-width: 768px) {
        font-size: 0.85rem;
      }
    }
  }
`;

export const PortfolioFooter = styled.div`
  margin-top: 1.5rem;
  text-align: right;

  a {
    color: #d4af37; /* Gold */
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    transition: color 0.3s ease;

    &:hover {
      text-decoration: underline;
      color: #ffd700; /* Bright Gold */
    }

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`;

export const OverviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(30, 30, 30, 0.9);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;

  div {
    text-align: center;
    flex: 1;
    margin: 0 1rem;

    h4 {
      font-size: 1.2rem;
      color: #ffffff;
      font-family: 'Lora', serif;
      margin-bottom: 0.5rem;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }

    p {
      font-size: 1rem;
      color: #cccccc;
      font-family: 'Open Sans', sans-serif;

      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
  }
`;

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover::after {
    content: attr(text);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    background-color: #444;
    color: #ffffff;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    font-family: 'Open Sans', sans-serif;
    white-space: nowrap;
    z-index: 100;
  }
`;

// New Component: ChartContainer
export const ChartContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;

  canvas {
    max-width: 100%;
    height: auto;
  }
`;

export const EditableCell = styled.td`
  padding: 0.75rem;
  border-bottom: 1px solid #444;
  background-color: ${(props) => (props.isEditing ? '#2a2a2a' : 'transparent')};
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  position: relative;

  &:focus {
    outline: 2px solid #D4AF37;
    background-color: #333;
  }
`;

export const SaveIcon = styled.span`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #D4AF37;
  visibility: ${(props) => (props.isEditing ? 'visible' : 'hidden')};
`;

export const EditButton = styled.button`
  padding: 0.5rem 1rem;
  background: #007bff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    background: #0056b3;
  }

  &:active {
    transform: scale(0.98);
  }
`;
