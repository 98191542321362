// src/components/WealthManagement/Widgets/OverallPortfolioSummaryWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../WealthManagementWidget.styles';

const OverallPortfolioSummaryWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Overall Portfolio Summary</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Total Portfolio Value:</span>
          <span>$2.3B</span>
        </DataRow>
        <DataRow>
          <span>YTD Performance:</span>
          <span>+7.8%</span>
        </DataRow>
        <DataRow>
          <span>Last Review:</span>
          <span>15th Nov 2024</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default OverallPortfolioSummaryWidget;
