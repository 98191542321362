// src/components/WealthManagement/WealthManagementSection.js

import React from 'react';
import { GridContainer } from '../MainSection/MainSection.styles';
import OverallPortfolioSummaryWidget from './Widgets/OverallPortfolioSummaryWidget';
import AssetAllocationWidget from './Widgets/AssetAllocationWidget';
import PerformanceTrackingWidget from './Widgets/PerformanceTrackingWidget';
import CashFlowManagementWidget from './Widgets/CashFlowManagementWidget';
import RiskManagementWidget from './Widgets/RiskManagementWidget';
import InvestmentStrategyReviewWidget from './Widgets/InvestmentStrategyReviewWidget';

const WealthManagementSection = ({ portfolio }) => {
  return (
    <GridContainer>
      <div className="grid-item">
        <OverallPortfolioSummaryWidget />
      </div>
      <div className="grid-item">
        <AssetAllocationWidget portfolio={portfolio} />
      </div>
      <div className="grid-item">
        <PerformanceTrackingWidget portfolio={portfolio} />
      </div>
      <div className="grid-item">
        <CashFlowManagementWidget />
      </div>
      <div className="grid-item">
        <RiskManagementWidget />
      </div>
      <div className="grid-item">
        <InvestmentStrategyReviewWidget />
      </div>
    </GridContainer>
  );
};

export default WealthManagementSection;
