// src/components/Widgets/DocumentManagementWidget.js
import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetBody, WidgetFooter } from '../Widgets/Widget.styles';

const DocumentManagementWidget = () => (
  <WidgetContainer>
    <WidgetHeader>
      <h3>Document Management</h3>
    </WidgetHeader>
    <WidgetBody>
      <p>Access and manage your important documents securely.</p>
    </WidgetBody>
    <WidgetFooter>
      <a href="#">View Documents</a>
    </WidgetFooter>
  </WidgetContainer>
);

export default DocumentManagementWidget;
