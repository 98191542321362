import React from 'react';
import { PortfolioBody } from '../InvestmentPortfolio.styles';

const TransactionForm = ({ newTransaction, handleInputChange, addTransaction, isLoading }) => (
  <PortfolioBody>
    <div>
      <input
        type="text"
        name="assetName"
        placeholder="Asset Name"
        value={newTransaction.assetName}
        onChange={handleInputChange}
        disabled={isLoading}
      />
      <input
        type="text"
        name="symbol"
        placeholder="Symbol"
        value={newTransaction.symbol}
        onChange={handleInputChange}
        disabled={isLoading}
      />
      <select
        name="assetClass"
        value={newTransaction.assetClass}
        onChange={handleInputChange}
        disabled={isLoading}
      >
        <option value="">Select Asset Class</option>
        <option value="Stock">Stock</option>
        <option value="Bond">Bond</option>
        <option value="Alternative">Alternative</option>
        <option value="Cash">Cash</option>
      </select>
      <input
        type="date"
        name="purchaseDate"
        value={newTransaction.purchaseDate}
        onChange={handleInputChange}
        disabled={isLoading}
      />
      <input
        type="number"
        name="quantity"
        placeholder="Quantity"
        value={newTransaction.quantity}
        onChange={handleInputChange}
        disabled={isLoading}
      />
      <input
        type="number"
        name="purchasePrice"
        placeholder="Purchase Price"
        value={newTransaction.purchasePrice}
        onChange={handleInputChange}
        disabled={isLoading}
      />
      <button onClick={addTransaction} disabled={isLoading}>
        {isLoading ? 'Adding...' : 'Add Transaction'}
      </button>
    </div>
  </PortfolioBody>
);

export default TransactionForm;
