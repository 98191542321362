import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetBody } from '../Widgets/Widget.styles';

const CalendarWidget = () => (
  <WidgetContainer>
    <WidgetHeader>
      <h3>Calendar</h3>
    </WidgetHeader>
    <WidgetBody>
      <p>No upcoming events</p>
    </WidgetBody>
  </WidgetContainer>
);

export default CalendarWidget;
