import React from 'react';
import { OverviewContainer, ChartContainer } from '../InvestmentPortfolio.styles';
import { Pie } from 'react-chartjs-2';
import { getAssetClassAllocation } from './api';

const PortfolioOverview = ({ totalGainsLosses, totalROI, totalAnnualizedReturn, portfolio }) => (
  <OverviewContainer>
    <div>
      <h4>Total Gains/Losses</h4>
      <p>${totalGainsLosses}</p>
      <h4>ROI</h4>
      <p>{totalROI}%</p>
      <h4>Annualized Returns</h4>
      <p>{totalAnnualizedReturn}%</p>
    </div>
    <div>
      <h4>Allocation by Asset Class</h4>
      <ChartContainer>
        <Pie data={getAssetClassAllocation(portfolio)} />
      </ChartContainer>
    </div>
  </OverviewContainer>
);

export default PortfolioOverview;
