import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from './EstatesTrustsWidget.styles';

const AssetsHoldingsWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Assets & Holdings</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Total Assets:</span>
          <span>$450M</span>
        </DataRow>
        <DataRow>
          <span>Real Estate:</span>
          <span>$200M</span>
        </DataRow>
        <DataRow>
          <span>Investments:</span>
          <span>$150M</span>
        </DataRow>
        <DataRow>
          <span>Other Holdings:</span>
          <span>$100M</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default AssetsHoldingsWidget;
