// src/components/TaxPlanning/Widgets/ComplianceAuditRiskWidget.js

import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from '../TaxPlanningWidget.styles';

const ComplianceAuditRiskWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Compliance & Audit Risk</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Audit Risk:</span>
          <span>Low</span>
        </DataRow>
        <DataRow>
          <span>Last Audit:</span>
          <span>2019</span>
        </DataRow>
        <DataRow>
          <span>Compliance Status:</span>
          <span>100%</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default ComplianceAuditRiskWidget;
