import React from 'react';
import { MainSection as StyledMainSection, GridContainer } from './MainSection.styles';
import MessagesWidget from '../Mail/MessageWidget/MessageWidget';
import CalendarWidget from './Widgets/CalendarWidget';
import NewsWidget from './Widgets/NewsWidget';
import EconIndicatorsWidget from './Widgets/EconIndicatorsWidget';
import InvestmentPortfolio from '../FinancialTracking/InvestmentPortfolio/InvestmentPortfolio';
import FinancialTrackingDashboard from '../FinancialTracking/FinancialTrackingDashboard';
import DocumentManagementWidget from './Widgets/DocumentManagementWidget';
import EstatesTrustsSection from '../EstatesTrusts/EstatesTrustsSection';
import TaxPlanningWidget from '../TaxPlanningWidget/TaxPlanningWidget';
import WealthManagementWidget from '../WealthManagementWidget/WealthManagementWidget';
import AdminDashboard from '../../admin/DataManagement/AdminDashboard';

const MainSection = ({ activeSection }) => {
  return (
    <StyledMainSection>
      {activeSection === 'Overview' && (
        <GridContainer>
          <div className="grid-item grid-item-large">
            <InvestmentPortfolio />
          </div>
          <div className="grid-item grid-item-small">
            <EconIndicatorsWidget />
          </div>
          <div className="grid-item grid-item-small">
            <NewsWidget />
          </div>
          <div className="grid-item grid-item-small">
            <MessagesWidget />
          </div>
          <div className="grid-item grid-item-small">
            <CalendarWidget />
          </div>
        </GridContainer>
      )}
      {activeSection === 'Financials' && (
        <>
          <FinancialTrackingDashboard />
        </>
      )}
      {activeSection === 'Documents' && (
        <DocumentManagementWidget />
      )}
      {activeSection === 'Trusts' && (
        <EstatesTrustsSection />  
      )}
      {activeSection === 'Tax' && (
        <TaxPlanningWidget />
      )}
      {activeSection === 'Wealth' && (
        <WealthManagementWidget />
      )}
      {activeSection === 'Mail' && (
        <MessagesWidget />
      )}
      {activeSection === 'AdminDashboard' && ( // Add case for AdminDashboard
        <AdminDashboard />
      )}
    </StyledMainSection>
  );
};

export default MainSection;
