import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from './EstatesTrustsWidget.styles';

const BeneficiaryManagementWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Beneficiary Management</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Primary Beneficiaries:</span>
          <span>3</span>
        </DataRow>
        <DataRow>
          <span>Last Distribution:</span>
          <span>15th Nov 2023 - $5M</span>
        </DataRow>
        <DataRow>
          <span>Next Distribution:</span>
          <span>15th Feb 2024 - $7M</span>
        </DataRow>
        <DataRow>
          <span>Pending Requests:</span>
          <span>2</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default BeneficiaryManagementWidget;
