import React from 'react';
import { WidgetContainer, WidgetHeader, WidgetContent, DataRow } from './EstatesTrustsWidget.styles';

const TrustEstatePlanningWidget = () => {
  return (
    <WidgetContainer>
      <WidgetHeader>Trust & Estate Planning</WidgetHeader>
      <WidgetContent>
        <DataRow>
          <span>Active Trusts:</span>
          <span>5</span>
        </DataRow>
        <DataRow>
          <span>Last Will Update:</span>
          <span>10th Oct 2023</span>
        </DataRow>
        <DataRow>
          <span>Next Planning Meeting:</span>
          <span>20th Jan 2024</span>
        </DataRow>
      </WidgetContent>
    </WidgetContainer>
  );
};

export default TrustEstatePlanningWidget;
