import React from 'react';
import { EditableCell, EditButton, ChartContainer } from '../InvestmentPortfolio.styles';
import { Line } from 'react-chartjs-2';
import { getHistoricalPerformance } from './api';

const PortfolioTable = ({ portfolio, handleEditChange, editingIndex, setEditingIndex, saveEdit }) => (
  <table>
    <thead>
      <tr>
        <th>Asset Name</th>
        <th>Symbol</th>
        <th>Asset Class</th>
        <th>Purchase Date</th>
        <th>Quantity</th>
        <th>Purchase Price</th>
        <th>Current Price</th>
        <th>Value</th>
        <th>Edit</th>
        <th>Performance</th>
      </tr>
    </thead>
    <tbody>
      {portfolio.map((asset, index) => (
        <tr key={index}>
          <EditableCell
            contentEditable={editingIndex === index}
            suppressContentEditableWarning={true}
            onInput={(e) => handleEditChange(e, index)}
            name="assetName"
            isEditing={editingIndex === index}
          >
            {asset.assetName}
          </EditableCell>
          <EditableCell
            contentEditable={editingIndex === index}
            suppressContentEditableWarning={true}
            onInput={(e) => handleEditChange(e, index)}
            name="symbol"
            isEditing={editingIndex === index}
          >
            {asset.symbol}
          </EditableCell>
          <td>{asset.assetClass}</td>
          <td>{asset.purchaseDate}</td>
          <EditableCell
            contentEditable={editingIndex === index}
            suppressContentEditableWarning={true}
            onInput={(e) => handleEditChange(e, index)}
            name="quantity"
            isEditing={editingIndex === index}
          >
            {asset.quantity}
          </EditableCell>
          <EditableCell
            contentEditable={editingIndex === index}
            suppressContentEditableWarning={true}
            onInput={(e) => handleEditChange(e, index)}
            name="purchasePrice"
            isEditing={editingIndex === index}
          >
            ${asset.purchasePrice.toFixed(2)}
          </EditableCell>
          <td>${asset.currentPrice.toFixed(2)}</td>
          <td>${(asset.quantity * asset.currentPrice).toFixed(2)}</td>
          <td>
            {editingIndex === index ? (
              <EditButton onClick={() => saveEdit(index)}>Save</EditButton>
            ) : (
              <EditButton onClick={() => setEditingIndex(index)}>Edit</EditButton>
            )}
          </td>
          <td>
            <ChartContainer>
              <Line data={getHistoricalPerformance(asset.historicalData)} />
            </ChartContainer>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default PortfolioTable;
